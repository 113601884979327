import React from 'react';
import { graphql } from 'gatsby';
import {
  BlogHeader,
  BlogPagination,
  BlogPostGrid,
  BlogTemplateWrapper,
} from 'partials';
import { BlogAuthorMeta, PageHelmet } from 'components/pages';
import { liftNode, formatTitle } from 'utils';

export default class BlogAuthorTemplate extends React.Component {
  componentDidMount() {
    const { data, location } = this.props;
    const author = data.ghostAuthor;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Custom-PageView',
      pagePath: `${location.pathname}`,
      pageTitle: `${formatTitle(author.name)}`,
    });
  }

  render() {
    const { data, pageContext } = this.props;
    const author = data.ghostAuthor;
    const posts = data.allGhostPost.edges.map(liftNode);

    return (
      <BlogTemplateWrapper>
        <PageHelmet
          data={author}
          description={author.bio || `Rangle.io blog author, ${author.name}`}
          type="Person"
          openGraphType="profile"
          extraMeta={<BlogAuthorMeta data={author} />}
        />
        <div className="blog-context">
          <BlogHeader isAuthor author={author} />
          <BlogPostGrid posts={posts} />
          <BlogPagination pageContext={pageContext} />
        </div>
      </BlogTemplateWrapper>
    );
  }
}

export const pageQuery = graphql`
  query GhostAuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostAuthor(slug: { eq: $slug }) {
      ...GhostAuthorFields
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          slug
          title
          feature_image
          tags {
            slug
          }
          published_at_pretty: published_at(formatString: "MMMM D, YYYY")
          authors {
            name
          }
        }
      }
    }
  }
`;
